// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-ansible-mysql-server-digitalocean-index-mdx": () => import("/opt/build/repo/src/pages/posts/ansible-mysql-server-digitalocean/index.mdx" /* webpackChunkName: "component---src-pages-posts-ansible-mysql-server-digitalocean-index-mdx" */),
  "component---src-pages-posts-kubernetes-operators-index-mdx": () => import("/opt/build/repo/src/pages/posts/kubernetes-operators/index.mdx" /* webpackChunkName: "component---src-pages-posts-kubernetes-operators-index-mdx" */),
  "component---src-pages-posts-pulumi-container-based-index-mdx": () => import("/opt/build/repo/src/pages/posts/pulumi-container-based/index.mdx" /* webpackChunkName: "component---src-pages-posts-pulumi-container-based-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

