import Code from '@components/Code';
import fakeNewsGradientCode from "../../../../src/pages/posts/kubernetes-operators/code/fake-news-gradient.example";
import screenshot from "../../../../src/pages/posts/kubernetes-operators/code/screenshot.example";
import pulumi from "../../../../src/pages/posts/kubernetes-operators/code/pulumi.example";
import cr from "../../../../src/pages/posts/kubernetes-operators/code/cr.example";
import React from 'react';
export default {
  Code,
  fakeNewsGradientCode,
  screenshot,
  pulumi,
  cr,
  React
};