import Code from '@components/Code';
import fakeNewsGradientCode from "../../../../src/pages/posts/pulumi-container-based/code/fake-news-gradient.example";
import screenshot from "../../../../src/pages/posts/pulumi-container-based/code/screenshot.example";
import pulumi from "../../../../src/pages/posts/pulumi-container-based/code/pulumi.example";
import React from 'react';
export default {
  Code,
  fakeNewsGradientCode,
  screenshot,
  pulumi,
  React
};