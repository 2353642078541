import Code from '@components/Code';
import fakeNewsGradientCode from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/fake-news-gradient.example";
import ini from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/ini.example";
import co1 from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/co1.example";
import co2 from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/co2.example";
import co3 from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/co3.example";
import screenshot from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/screenshot.example";
import pulumi from "../../../../src/pages/posts/ansible-mysql-server-digitalocean/code/pulumi.example";
import CenteredImage from "../../../../src/components/CenteredImage";
import digitalocean1 from "../../../../src/assets/images/digitalocean-mysql/digitalocean1.png";
import do0 from "../../../../src/assets/images/digitalocean-mysql/do0.png";
import do1 from "../../../../src/assets/images/digitalocean-mysql/do1.png";
import folder from "../../../../src/assets/images/digitalocean-mysql/folder.png";
import React from 'react';
export default {
  Code,
  fakeNewsGradientCode,
  ini,
  co1,
  co2,
  co3,
  screenshot,
  pulumi,
  CenteredImage,
  digitalocean1,
  do0,
  do1,
  folder,
  React
};